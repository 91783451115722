import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import "../styles/WelcomeAnimation.scss";

const WelcomeScreen = ({ onAnimationComplete }) => {
  const [letters, setLetters] = useState([]);

  // Split the welcome message into individual letters
  const welcomeMessage = "welcome";

  useEffect(() => {
    setLetters(welcomeMessage.split("")); // Split by each letter

    // Automatically fade out the welcome screen after 3 seconds
    const timer = setTimeout(() => {
      onAnimationComplete(); // Call the callback to indicate the animation is done
    }, 1500); // Duration of the welcome animation

    return () => clearTimeout(timer); // Cleanup the timer
  }, [onAnimationComplete]);

  return (
    <motion.div
      className="welcome-screen"
      initial={{ opacity: 1 }} // Start fully visible
      animate={{ opacity: 1 }} // Keep it fully visible during the animation
      exit={{ opacity: 0 }} // Fade out when unmounting
      transition={{ duration: 1 }} // Control the fade-out duration
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {letters.map((letter, index) => (
        <motion.span
          key={index}
          className="welcome-letter"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, scale: [1, 1.1, 1] }}
          transition={{
            duration: 0.6,
            ease: [0.25, 0.1, 0.25, 1],
            delay: index * 0.1,
          }}
          whileHover={{ scale: 1.2 }}
        >
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default WelcomeScreen;
