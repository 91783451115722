import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import ScrollableContainer from "./components/ScrollableContainer";
import SideNav from "./components/SideNav.js";
import ProjectsUX from "./components/ProjectsUX.js";
import ProjectsWDD from "./components/ProjectsWDD.js";
import ProjectsAdobeIllustrator from "./components/ProjectsAdobeIllustrator.js";
import Testimonials from "./components/Testimonials.js";
import About from "./components/About.js";
import Contact from "./components/Contact.js";
import Footer from "./components/Footer.js";
import "../src/components/Firebase.js"; // Import the Firebase configuration
import WelcomeScreen from "../src/components/WelcomeAnimation.js"; // Import the WelcomeScreen component

// Lazy load components
const Home = React.lazy(() => import("./components/Home.js"));
const Education = React.lazy(() => import("./components/Education.js"));

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);

  useEffect(() => {
    const lastVisit = localStorage.getItem("lastVisit");
    const currentTime = new Date().getTime();
    const twoHours = 2 * 60 * 60 * 1000; // Two hours in milliseconds

    if (
      !lastVisit ||
      currentTime - lastVisit > twoHours ||
      !sessionStorage.getItem("hasSeenAnimation")
    ) {
      setShowWelcomeScreen(true);
      localStorage.setItem("lastVisit", currentTime); // Update the last visit time
      sessionStorage.setItem("hasSeenAnimation", "true"); // Mark animation as shown for the current session
    } else {
      setShowWelcomeScreen(false); // Skip the welcome screen if it's within two hours
    }
  }, []);

  const handleAnimationComplete = () => {
    setShowWelcomeScreen(false); // Hide the welcome screen once animation is done
  };

  return (
    <React.StrictMode>
      {showWelcomeScreen ? (
        <WelcomeScreen onAnimationComplete={handleAnimationComplete} />
      ) : (
        <>
          {/* LARGE SCREEN */}
          <div className="indexDivLarge">
            <SideNav />
            <div className="indexDiv MandL">
              <ScrollableContainer className="ScrollableContainer">
                <Suspense fallback={<div>Loading Home...</div>}>
                  <Home />
                </Suspense>
                {/* <EducationCircles /> */}
                <ProjectsWDD />
                <ProjectsUX />
                <ProjectsAdobeIllustrator />
                <Suspense fallback={<div>Loading Education...</div>}>
                  <Education />
                </Suspense>
                <Testimonials />
                <About />
                <Contact />
                <Footer />
              </ScrollableContainer>
            </div>
          </div>
        </>
      )}
    </React.StrictMode>
  );
};

root.render(<App />);
