import React from "react";
import "../styles/TestimonialCard.scss";
import linkedinIcon from "../assets/icons/Linkedin.svg";
import behanceIcon from "../assets/icons/Behance.svg";

const socialIcons = {
  linkedin: linkedinIcon,
  behance: behanceIcon,
};

const TestimonialCard = ({ photo, name, text, className, socialLinks }) => {
  return (
    <div className={`testimonial-card ${className}`}>
      <img src={photo} alt={name} className="testimonial-photo" />
      <div className="m-cinzel-h3 testimonialName">{name}</div>
      <div className="m-body">{text}</div>

      {/* Render social media links */}
      <div className="social-media-links">
        {socialLinks &&
          socialLinks.map(({ platform, url, icon }) => (
            <a
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <img
                src={icon} // Ensure you use the correct icon passed
                className={`socialMediaIcon ${platform}`}
                alt={`${platform} Icon`}
              />
            </a>
          ))}
      </div>
    </div>
  );
};

export default TestimonialCard;
