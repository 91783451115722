import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard.js";
import Section from "./Section.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.scss";
import imgOne from "../assets/images/Stickers.jpg";
import imgTwo from "../assets/images/watchReplica.jpg";
import imgThree from "../assets/images/gigPoster.jpg";

const projects = [
  {
    id: 1,
    title: "ALEBRIJE STICKERS",
    description:
      "Concept Ideation, Style Selection, Sketching, Vector Creation, Adobe Illustrator, Print-Ready.",
    backgroundImage: imgOne,
    caseStudyLink: "https://www.behance.net/gallery/182205945/Vector-Graphics-Alebrijes-Stickers", // Update with actual link
    figmaLink: "https://mir-s3-cdn-cf.behance.net/project_modules/1400/020d86182205945.6529465b2a968.png",
    caseStudyLabel: "Behance Post",
    figmaLabel: "Vector Image",
  },
  {
    id: 2,
    title: "WATCH REPLICA",
    description:
      "Manually Drawn Photo-Realistic Vector, Pixel-By-Pixel Analysis, By-Eye Color Matching, Adobe Illustrator; Techniques Used: Texture, Gradients, Shapes, Layers, Opacity, etc.",
    backgroundImage: imgTwo,
    caseStudyLink: "https://www.behance.net/gallery/182179533/Vector-Graphics-Watch-Replica", // Update with actual link
    figmaLink: "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/4eb255182179533.671c854ec2a48.png",
    caseStudyLabel: "Behance Post",
    figmaLabel: "Vector Image",
  },
  {
    id: 3,
    title: "GIG POSTER",
    description:
      "Brainstorming, Sketching, Vector Creation, Font Selection, Iterative Process, Adobe Illustrator, Photoshop Mockups.",
    backgroundImage: imgThree,
    caseStudyLink: "https://www.behance.net/gallery/182180953/Vector-Graphics-Gig-Poster",
    figmaLink: "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/02cfc1182180953.6718579b53dd6.png",
    caseStudyLabel: "Behance Post",
    figmaLabel: "Vector Image",
  },
  // Add more projects as needed
];

const PortfolioProjects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Disable browser's swipe-back navigation
  useEffect(() => {
    const preventNavigation = (e) => {
      if (e.deltaX > 50 || e.deltaX < -50) {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", preventNavigation);

    return () => {
      window.removeEventListener("wheel", preventNavigation);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3, // Default to show 3 slides
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    afterChange: (index) => setActiveIndex(index),
    beforeChange: (current, next) => {
      if (current === projects.length - 1 && next === 0) {
        setTimeout(() => {
          setActiveIndex(1); // Go to second slide
        }, 300);
      }
    },
    responsive: [
      {
        breakpoint: 1500, // For screens <= 1499px
        settings: {
          slidesToShow: 2, // Show 2 slides
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // For screens <= 1199px
        settings: {
          slidesToShow: 1, // Show 1 slide
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="portfolio-projects">
      <Section id="ADOBEILLUSTRATOR" className="section" padding="30px">
        <div className="H2sProjects">
          <div className="m-cinzel-h2 h2-1AI">ADOBE</div>
          <div className="m-herr-h2 h2-2AI">Illustrator</div>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              backgroundImage={project.backgroundImage}
              isActive={index === activeIndex}
              caseStudyLink={project.caseStudyLink}
              figmaLink={project.figmaLink}
              caseStudyLabel={project.caseStudyLabel}
              figmaLabel={project.figmaLabel}
            />
          ))}
        </Slider>
      </Section>
    </div>
  );
};

export default PortfolioProjects;
