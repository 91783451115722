import React, { useEffect, useRef, useState } from "react";
import "../styles/ProjectCard.scss";

const ProjectCard = ({ title, description, backgroundImage, isActive, caseStudyLink, figmaLink, caseStudyLabel, figmaLabel }) => {
  const imageRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const imgElement = imageRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log(`Image loaded: ${title}`);
          setIsLoaded(true); // Set loaded state to true
          observer.unobserve(imgElement); // Stop observing once the image has been loaded
        }
      });
    });

    if (imgElement) {
      observer.observe(imgElement); // Start observing the image element
    }

    return () => {
      if (imgElement) {
        observer.unobserve(imgElement); // Cleanup observer on unmount
      }
    };
  }, [title]);

  return (
    <div
      className={`project-card ${isActive ? "active" : ""}`}
      style={{ backgroundImage: isLoaded ? `url(${backgroundImage})` : "none" }} // Only set the background image if loaded
      ref={imageRef} // Attach the ref to the div
    >
      <div className={`overlay ${isActive ? "fade-in" : ""}`}>
        <div className="projectCardTextAndButtons">
          <h2 className="m-cinzel-h3">{title}</h2>
          <p className="m-body">{description}</p>
          <p className="buttons">
            <a href={caseStudyLink} target="_blank" rel="noopener noreferrer">
              <button className="m-body">{caseStudyLabel}</button>
            </a>
            <a href={figmaLink} target="_blank" rel="noopener noreferrer">
              <button className="m-body">{figmaLabel}</button>
            </a>
          </p>
        </div>
      </div>
      {!isLoaded && <div className="image-placeholder">Loading...</div>} {/* Placeholder while loading */}
    </div>
  );
};

export default ProjectCard;
