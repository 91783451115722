import React from "react";
import "../styles/Section.scss"; // Import the CSS file for Section component

const Section = ({
  id,
  background,
  backgroundImage,
  content,
  padding,
  children,
}) => {
  const sectionStyle = {
    backgroundColor: background,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
    
  };

  return (
    <div id={id} className="section" style={sectionStyle}>
      <div className="section-inner">
        {content}
        {children}
      </div>
    </div>
  );
};

export default Section;
