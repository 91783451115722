import React, { useState } from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard.js";
import Section from "./Section.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.scss";
import imgTwo from "../assets/images/GuidedTree.jpg";
import imgOne from "../assets/images/additionalCollections.jpg";
import imgThree from "../assets/images/OneSearch.jpg";

const projects = [
  {
    id: 1,
    title: "GUIDED TREE | familysearch®",
    description:
      "Problem Identification, UX Analysis, Brainstorming Solutions, Prototype Creation, User Testing, Results Compilation, Data Analysis, Solution Decision.",
    backgroundImage: imgTwo,
    caseStudyLink: "https://www.figma.com/proto/RhFLWTaUzReWU9UKvItlhB/Portfolio?page-id=1%3A129&node-id=845-15823&node-type=frame&viewport=-16110%2C-7431%2C0.17&t=mU9q3WwNZmkFWWyU-8&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=845%3A15823&hide-ui=1",
    figmaLink: "https://www.figma.com/design/RhFLWTaUzReWU9UKvItlhB/Portfolio?node-id=719-14810&t=ZLppySe7U8hqStaW-1",
    caseStudyLabel: "Case Study",
    figmaLabel: "Figma Files",
  },
  {
    id: 2,
    title: "ONE SEARCH | familysearch®",
    description:
      "UX/UI Improvements, Section Modifications, Usability Enhancements, Figma Prototyping, Developer Collaboration.",
    backgroundImage: imgThree,
    caseStudyLink: "https://www.figma.com/proto/RhFLWTaUzReWU9UKvItlhB/Portfolio?page-id=1%3A129&node-id=806-16203&node-type=canvas&viewport=-15235%2C-17572%2C0.47&t=L9gqfLkIHIkigT6W-8&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=806%3A16203&hide-ui=1",
    figmaLink: "https://www.figma.com/design/RhFLWTaUzReWU9UKvItlhB/Portfolio?node-id=718-9575&t=ZLppySe7U8hqStaW-1",
    caseStudyLabel: "Case Study",
    figmaLabel: "Figma Files",
  },
  {
    id: 3,
    title: "UI/UX Analysis | familysearch®",
    description:
      "Design Gap Identification, UI Evaluation, Design Analysis in Figma, Changes Documentation for Future Improvements.",
    backgroundImage: imgOne,
    caseStudyLink: "https://www.figma.com/proto/RhFLWTaUzReWU9UKvItlhB/Portfolio?page-id=1%3A129&node-id=905-41339&node-type=frame&viewport=-13328%2C-25131%2C0.29&t=uKGJIcjEFFBAr0St-8&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=905%3A41339&hide-ui=1",
    figmaLink: "https://www.figma.com/design/RhFLWTaUzReWU9UKvItlhB/Portfolio?node-id=720-25389&t=ZLppySe7U8hqStaW-1",
    caseStudyLabel: "Case Study",
    figmaLabel: "Figma Files",
  },
  // Add more projects as needed
];

const PortfolioProjects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (index) => setActiveIndex(index),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="portfolio-projects">
      <Section id="USEREXPERIENCE" className="section" padding="30px">
        <div className="H2sProjects">
          <div className="m-cinzel-h2 h2-1UX">User</div>
          <div className="m-herr-h2 h2-2UX">Experience</div>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              backgroundImage={project.backgroundImage}
              isActive={index === activeIndex}
              caseStudyLink={project.caseStudyLink}
              figmaLink={project.figmaLink}
              caseStudyLabel={project.caseStudyLabel} // Pass the label
              figmaLabel={project.figmaLabel} // Pass the label
            />
          ))}
        </Slider>
      </Section>
    </div>
  );
};

export default PortfolioProjects;