import React, { useRef, useEffect, useState, lazy, Suspense } from "react";
import "../styles/About.scss";
import Section from "./Section";

// Import images
import oneSmall from "../assets/images/oneSmall.png";
import oneMedium from "../assets/images/oneMedium.png";
import oneLarge from "../assets/images/oneLarge.png";

// import twoSmall from "../assets/images/twoSmall.png";
// import twoMedium from "../assets/images/twoMedium.png";
// import twoLarge from "../assets/images/twoLarge.png";

// import threeSmall from "../assets/images/threeSmall.png";
// import threeMedium from "../assets/images/threeMedium.png";
// import threeLarge from "../assets/images/threeLarge.png";

import fourSmall from "../assets/images/fourSmall.png";
import fourMedium from "../assets/images/fourMedium.png";
import fourLarge from "../assets/images/fourLarge.png";

// Lazy load the ImageSlider component
const ImageSlider = lazy(() => import("./ImageSlider"));

// Array of image sets
const images = [
  {
    small: oneSmall,
    medium: oneMedium,
    large: oneLarge,
  },
  // {
  //   small: twoSmall,
  //   medium: twoMedium,
  //   large: twoLarge,
  // },
  // {
  //   small: threeSmall,
  //   medium: threeMedium,
  //   large: threeLarge,
  // },
  {
    small: fourSmall,
    medium: fourMedium,
    large: fourLarge,
  },
];

const About = () => {
  const aboutBodyRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [scrollStep, setScrollStep] = useState(0); // New state to track scroll steps

  // Check if at the bottom of the scrollable area
  useEffect(() => {
    const handleScroll = () => {
      if (aboutBodyRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = aboutBodyRef.current;
        setIsAtBottom(scrollTop + clientHeight >= scrollHeight);
      }
    };

    const current = aboutBodyRef.current;
    current.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const smoothScrollTo = (targetY, duration) => {
    const startY = aboutBodyRef.current.scrollTop;
    const distance = targetY - startY;
    const startTime = performance.now();

    const scrollStep = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1); // Clamp progress to 0-1
      const ease =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2; // Ease function
      aboutBodyRef.current.scrollTop = startY + distance * ease;

      if (progress < 1) {
        requestAnimationFrame(scrollStep);
      }
    };

    requestAnimationFrame(scrollStep);
  };

  const scrollToPosition = (position) => {
    if (aboutBodyRef.current) {
      smoothScrollTo(position, 1600); // Set duration to 1600 ms
    }
  };

  const scrollToTop = () => {
    if (aboutBodyRef.current) {
      smoothScrollTo(0, 1600); // Set duration to 1600 ms
      setScrollStep(0); // Reset the scroll step after scrolling to the top
    }
  };

  const handleScrollIndicatorClick = () => {
    if (isAtBottom) {
      scrollToTop();
    } else {
      scrollToNextPosition();
    }
  };

  const scrollToNextPosition = () => {
    if (aboutBodyRef.current) {
      const scrollHeight = aboutBodyRef.current.scrollHeight;
      const quarter = scrollHeight / 4;
      const half = scrollHeight / 2;
      const threeQuarters = (scrollHeight * 3) / 4;

      switch (scrollStep) {
        case 0:
          scrollToPosition(quarter); // Scroll to the first position
          setScrollStep(1);
          break;
        case 1:
          scrollToPosition(half); // Scroll to the second position
          setScrollStep(2);
          break;
        case 2:
          scrollToPosition(threeQuarters); // Scroll to the third position
          setScrollStep(3);
          break;
        case 3:
          scrollToPosition(scrollHeight); // Scroll to the bottom
          setScrollStep(4);
          break;
        case 4:
          scrollToTop();
          break;
        default:
          break;
      }
    }
  };

  // Log a message when the component mounts
  useEffect(() => {
    console.log("About component mounted");
  }, []);

  return (
    <div className="About">
      <Section id="ABOUT" className="section" padding="30px">
        <div className="slider-container">
          <Suspense fallback={<div>Loading...</div>}>
            <ImageSlider images={images} />
          </Suspense>
        </div>

        <div className="additional-content">
          <div className="AboutH2s-">
            <div className="m-herr-h2">about me</div>
            <div className="m-cinzel-h4">
              “beauty doesn’t just happen; it’s designed”
            </div>
          </div>

          <div className="currentContainer here">
            <div className="m-body aboutBody" ref={aboutBodyRef}>
              Designing websites isn't just my job—it's something I truly enjoy.
              My journey started with an online boot camp where I learned the
              fundamentals of HTML, CSS, and JavaScript. I was hooked right
              away, which led me to major in Web Design and Development. Over
              time, I’ve discovered that I love creating designs that are both
              functional and visually pleasing.
              <br />
              <br />
              Growing up, I often heard the phrase, “Be the best at what you
              do,” and I’ve applied that mindset to everything—whether it's a
              personal project, a job, or even the smallest task. Former
              coworkers and clients appreciate my commitment to delivering
              high-quality work. I’m known for being positive, reliable,
              punctual, and detail-oriented.
              <br />
              <br />
              I work well in teams and can just as easily take the lead when
              needed. Whether I’m following instructions or brainstorming
              creative ideas, I’m fully committed to doing my best.
              <br />
              <br />
              Originally from Mexico, I moved to the U.S. for college and have
              come to deeply appreciate American culture while keeping my
              Mexican roots close. This diverse background has broadened my
              perspective and enriched my approach to design. Being fluent in
              both English and Spanish also brings an added layer of versatility
              to my work.
              <br />
              <br />
              When I'm not designing, you'll find me singing, dancing,
              cross-country skiing when that's possible, or laughing at silly
              jokes.
              <br />
              <br />I embrace challenging tasks and approach them with
              enthusiasm. Over the years, I’ve learned that beauty doesn’t just
              happen—it’s designed.
              <br />
              <br />
              I’m always grateful for the opportunities I'm given, and I show
              that appreciation by doing my very best. Let’s connect and
              create something great together!
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              ...
            </div>
          </div>
        </div>

        {/* Gradient + Scroll Arrow Container */}
        <div className="gradientRectangleAndScrollArrow">
          <div className="gradientRectangle"></div>
          <div
            className="scroll-indicator"
            onClick={handleScrollIndicatorClick}
          >
            {isAtBottom ? "Back to Top" : "Click to Scroll"}{" "}
            <span>{isAtBottom ? "↑" : "↓"}</span>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default About;
