  // {
  //   photo: testimonialTwo,
  //   name: "Valerie Villalobos",
  //   text: "Before you begin writing, it’s essential to understand the purpose of the letter. Typically, letters of recommendation are requested for educational applications, job applications, scholarships, or other opportunities. The letter should provide insights into the candidate’s qualifications, skills, character, and suitability for the specific position or opportunity.",
  //   socialLinks: [
  //     { platform: "LinkedIn", url: "https://www.linkedin.com/in/valerie-villalobos-thomas-58280417a/", icon: linkedin },
  //   ],
  // },
    // {
  //   photo: testimonialOne,
  //   name: "Dominick Young",
  //   text: "A letter of recommendation is a valuable document that can make a significant difference in someone’s academic or professional journey. Whether you are a teacher, employer, colleague, or mentor, being asked to write a letter of recommendation is an honor and a responsibility. This article will guide you through the process of writing an effective and impactful letter of recommendation, helping you showcase the strengths and qualifications of the individual you are recommending!",
  // },

  import React, { useState, useRef, useEffect } from "react";
  import TestimonialCard from "./TestimonialCard";
  import "../styles/Testimonials.scss";
  import Section from "./Section";
  import testimonialOne from "../assets/images/DominickYoung.png";
  import testimonialTwo from "../assets/images/ValerieVillalobos.png";
  import testimonialThree from "../assets/images/DavidMiller.png";
  import testimonialFour from "../assets/images/SeanMurdock.png";
  import testimonialFive from "../assets/images/JulieAndersone.png";
  import linkedin from "../assets/icons/Linkedin.svg";
  
  // Array of testimonials data
  const testimonials = [
    // Testimonial data
    {
      photo: testimonialThree,
      name: "David Miller",
      text: '"I have had the good fortune of working with Cindy as an intern, a student, and as a private contractor. I was able to see Cindy progress through each of these levels and excel in each role. She is professional, knowledgeable, versatile, organized, and a delight of a team player in every sense."',
      socialLinks: [{ platform: "LinkedIn", url: "https://www.linkedin.com/in/david-miller-byui", icon: linkedin }],
    },
    {
      photo: testimonialFour,
      name: "Sean Murdock",
      text: '"[Cindy] [worked] for a client of mine in Southeast Idaho, where [she] [produced] over a dozen beautiful prototype mockups... The customer [was] elated with the work she [did] for their company."',
      socialLinks: [{ platform: "LinkedIn", url: "https://www.linkedin.com/in/sean-murdock/", icon: linkedin }],
    },
    {
      photo: testimonialFive,
      name: "Julie Anderson",
      text: '"I have had the privilege of observing Cindy’s academic and extracurricular achievements firsthand... [Cindy] went on to get first place nationally with her team. Her work ethic is incredible..."',
      socialLinks: [{ platform: "LinkedIn", url: "https://www.linkedin.com/in/julie-anderson-ms-bis-ms-ed-07781285/", icon: linkedin }],
    },
  ];
  
  const Testimonials = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Track the current testimonial index
    const scrollContainerRef = useRef(null); // Reference for the scrollable container
  
    // Handles click on the slider dot to navigate to a specific testimonial
    const handleDotClick = (index) => {
      setActiveIndex(index); // Update active index
      scrollToCard(index); // Scroll to the corresponding card
    };
  
    // Scrolls to the testimonial card at the given index
    const scrollToCard = (index) => {
      if (scrollContainerRef.current) {
        const scrollAmount = scrollContainerRef.current.clientWidth * index; // Calculate scroll position
        scrollContainerRef.current.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    };
  
    // Update the active index based on scroll position
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const scrollLeft = scrollContainerRef.current.scrollLeft;
        const cardWidth = scrollContainerRef.current.clientWidth;
        const newIndex = Math.round(scrollLeft / cardWidth);
        setActiveIndex(newIndex);
      }
    };
  
    // Attach and detach the scroll event listener on mount/unmount
    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", handleScroll);
      }
  
      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);
  
    const handlePrev = () => {
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : testimonials.length - 1));
      scrollToCard((activeIndex > 0 ? activeIndex - 1 : testimonials.length - 1));
    };
  
    const handleNext = () => {
      setActiveIndex((prevIndex) => (prevIndex < testimonials.length - 1 ? prevIndex + 1 : 0));
      scrollToCard((activeIndex < testimonials.length - 1 ? activeIndex + 1 : 0));
    };
  
    return (
      <div className="testimonials-section">
        <Section id="TESTIMONIALS" className="TestimonialsSection">
          <span className="testimonials-background-text testimonial-background">
            Testimonials
          </span>
  
          <div className="testimonialsContainer">
            {/* Previous Arrow */}
            <button className="slick-prev" onClick={handlePrev} aria-label="Previous"></button>
  
            {/* Scrollable container for testimonials */}
            <div className="testimonials-scroll-container" ref={scrollContainerRef}>
              {testimonials.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  photo={testimonial.photo}
                  name={testimonial.name}
                  text={testimonial.text}
                  socialLinks={testimonial.socialLinks}
                  className={index === activeIndex ? "active" : ""}
                />
              ))}
            </div>
  
            {/* Next Arrow */}
            <button className="slick-next" onClick={handleNext} aria-label="Next"></button>
  
            <div className="slider-dots">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  className={`dot ${index === activeIndex ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                  aria-label={`Testimonial ${index + 1}`}
                ></button>
              ))}
            </div>
          </div>
        </Section>
      </div>
    );
  };
  
  export default Testimonials;
  
