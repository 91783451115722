import React, { useState, useEffect } from "react";
import "../styles/Footer.scss";
import behance from "../assets/icons/Behance.svg";
import linkedin from "../assets/icons/Linkedin.svg";

function Footer() {
  const [footerText, setFooterText] = useState("Let's Stay connected!");

  // Check for screen resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setFooterText("Thanks for visiting!");
      } else {
        setFooterText("Let's Stay connected!");
      }
    };

    // Set the initial text based on the current window size
    handleResize();

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="Footer">
      <div className="m-herr-h3 footerText">{footerText}</div>
      <div className="foooter-icons">
        {/* Behance Icon */}
        <a
          href="https://www.behance.net/cindycastro4/projects" // Replace with your Behance URL
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={behance} className="socialMediaIcon Behance" alt="Behance Icon" />
        </a>

        {/* LinkedIn Icon */}
        <a
          href="https://www.linkedin.com/in/cindycasttc/en" // Replace with your LinkedIn URL
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} className="socialMediaIcon Linkedin" alt="LinkedIn Icon" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
