// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5gMiPRSIppHqDYGyUEGgPsEIlMblxU70",
  authDomain: "cindycasttc-50094.firebaseapp.com",
  projectId: "cindycasttc-50094",
  storageBucket: "cindycasttc-50094.appspot.com",
  messagingSenderId: "412623475367",
  appId: "1:412623475367:web:8a159c884944a98fc3c182",
  measurementId: "G-FMR4482SJJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export the Firebase app and any other services you want to use
export { app, analytics };
