import React, { useEffect, useState } from "react";
import Section from "./Section";
import "../styles/SideNav.scss";
import behance from "../assets/icons/Behance.svg";
import linkedin from "../assets/icons/Linkedin.svg";
import { ReactComponent as MyIcon } from '../assets/icons/C.svg';


function SideNav() {
  const [activeSection, setActiveSection] = useState(""); // State for active section

  const handleScroll = (sectionId) => {
    console.log(`Scrolling to section: ${sectionId}`); // Logging the section to scroll to
  
    // Find the section by id and scroll to it smoothly
    const section = document.getElementById(sectionId);
    if (section) {
      console.log(`Found section with id '${sectionId}'. Scrolling now...`); // Scrolling to section
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error(`Section with id '${sectionId}' not found.`); // Error if not found
    }
  };

  useEffect(() => {
    const sections = document.querySelectorAll("div.section"); // Changed to divs
    const observerOptions = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: 0.5, // trigger when 50% of the section is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id); // Set the active section based on the visible section
        }
      });
    }, observerOptions);

    // Observe each section
    sections.forEach((section) => {
      observer.observe(section);
    });

    // Clean up observer on unmount
    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="SideNav">
      <Section id="SideNav" className="section SideNav" padding="30px">
        <div className="SideNavTopContent">
          <div className="sideNavMyName" onClick={() => handleScroll("HOME")}>
     
            <MyIcon />
          </div>
          <div className="SideNavMiddleContent">
            <ul className="SideNavUl">
              <li
                className={`m-body nav-item ${activeSection === "WEBDESIGNDEVELOPMENT" ? "active" : ""}`}
                onClick={() => handleScroll("WEBDESIGNDEVELOPMENT")}
              >
                Web Design
              </li>
              <li
                className={`m-body nav-item ${activeSection === "USEREXPERIENCE" ? "active" : ""}`}
                onClick={() => handleScroll("USEREXPERIENCE")}
              >
                User Experience
              </li>
              <li
                className={`m-body nav-item ${activeSection === "ADOBEILLUSTRATOR" ? "active" : ""}`}
                onClick={() => handleScroll("ADOBEILLUSTRATOR")}
              >
                Adobe Illustrator
              </li>
              <li
                className={`m-body nav-item ${activeSection === "RESUME" ? "active" : ""}`}
                onClick={() => handleScroll("RESUME")}
              >
                Resume
              </li>
              <li
                className={`m-body nav-item ${activeSection === "TESTIMONIALS" ? "active" : ""}`}
                onClick={() => handleScroll("TESTIMONIALS")}
              >
                Testimonials
              </li>
              <li
                className={`m-body nav-item ${activeSection === "ABOUT" ? "active" : ""}`}
                onClick={() => handleScroll("ABOUT")}
              >
                About
              </li>
              <li
                className={`m-body nav-item ${activeSection === "CONTACT" ? "active" : ""}`}
                onClick={() => handleScroll("CONTACT")}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>

        <div className="SideNavBottomContent">
          <div className="m-herr-h3 footerNavText">-</div>
          <div className="footer-icons">
            <a
              href="https://www.behance.net/cindycastro4/projects"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={behance}
                className="socialMediaIcon Behance"
                alt="Behance Icon"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/cindycasttc/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedin}
                className="socialMediaIcon Linkedin"
                alt="LinkedIn Icon"
              />
            </a>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default SideNav;

