import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard.js";
import Section from "./Section.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.scss";
import imgOne from "../assets/images/spiritOfWicks.jpg";
import imgTwo from "../assets/images/carstro.jpg";
import imgThree from "../assets/images/oneLargeSecond.jpg";
import imgFour from "../assets/images/msrBanerM.jpg";

const projects = [
  {
    id: 1,
    title: "carstro",
    description:
      "First Place (National BPA Competition), Team Captain, Co-Designed, Co-Developed, Figma, Photoshop, Premiere Pro, ReactJS, CSS3, Github.",
    backgroundImage: imgTwo,
    caseStudyLink: "https://www.figma.com/proto/RhFLWTaUzReWU9UKvItlhB/Portfolio?page-id=1%3A129&node-id=811-17502&node-type=frame&viewport=-19592%2C-26235%2C0.64&t=SaH5n9M0l2itNC8I-8&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=811%3A17502&show-proto-sidebar=1&hide-ui=1",
    figmaLink: "https://carstro-15495.web.app/",
    caseStudyLabel: "Case Study",
    figmaLabel: "Live Website",
  },
  {
    id: 2,
    title: "MSR | familysearch®",
    description:
      "Redesigned, OG Tags, Banners, Copy, Image Selection & Optimization, Figma Designs & Prototypes.",
    backgroundImage: imgFour,
    caseStudyLink: "https://www.figma.com/proto/RhFLWTaUzReWU9UKvItlhB/Portfolio?page-id=1%3A129&node-id=690-1137&node-type=frame&viewport=-19592%2C-26235%2C0.64&t=SaH5n9M0l2itNC8I-8&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=690%3A1137&show-proto-sidebar=1&hide-ui=1",
    figmaLink: "https://www.familysearch.org/es/latam/merecen-ser-recordados",
    caseStudyLabel: "Case Study",
    figmaLabel: "Live Website",
  },
  {
    id: 3,
    title: "my portfolio | website",
    description:
      "Designed, Developed, Deployed, ReactJS, SCSS, Firebase, Github, Photoshop, UX Research, Figma Wireframes & Prototypes.",
    backgroundImage: imgThree,
    caseStudyLink: "https://www.figma.com/proto/RhFLWTaUzReWU9UKvItlhB/Portfolio?page-id=1%3A129&node-id=848-16157&node-type=section&viewport=305%2C-699%2C0.03&t=rmTz3aD48X8IsUIX-8&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=848%3A16157&hide-ui=1",
    figmaLink: "https://www.figma.com/design/RhFLWTaUzReWU9UKvItlhB/Portfolio?node-id=670-4128&t=HA8awgW5RDXrz5I1-1",
    caseStudyLabel: "Case Study",
    figmaLabel: "Figma Designs",
  },
  {
    id: 4,
    title: "THE SPIRIT OF WICKS",
    description:
      "Redesigned, Responsive Wix Site, Product Photography, Photoshop Mockups, Wix CMS, High-Definition Assets.",
    backgroundImage: imgOne,
    figmaLink: "https://www.thespiritofwicks.com/",
    figmaLabel: "Live Website",
  },
];

const PortfolioProjects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Disable browser's swipe-back navigation
  useEffect(() => {
    const preventNavigation = (e) => {
      if (e.deltaX > 50 || e.deltaX < -50) {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", preventNavigation);

    return () => {
      window.removeEventListener("wheel", preventNavigation);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500, // Adjusted for smooth transitions
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100, // Increases swipe sensitivity
    afterChange: (index) => setActiveIndex(index),
    beforeChange: (current, next) => {
      if (current === projects.length - 1 && next === 0) {
        // When moving from last to first, skip to the second slide
        setTimeout(() => {
          setActiveIndex(1); // Go to second slide
        }, 300); // Adjust the timing as needed
      }
    },
    responsive: [
      {
        breakpoint: 1500, // When the screen width is <= 1499px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // When the screen width is <= 1199px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="portfolio-projects">
      <Section id="WEBDESIGNDEVELOPMENT" className="section" padding="30px">
        <div className="H2sProjects">
          <div className="m-cinzel-h2 h2-1WDD">WEB DESIGN &</div>
          <div className="m-herr-h2 h2-2WDD">Development</div>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              backgroundImage={project.backgroundImage}
              isActive={index === activeIndex}
              caseStudyLink={project.caseStudyLink}
              figmaLink={project.figmaLink}
              caseStudyLabel={project.caseStudyLabel}
              figmaLabel={project.figmaLabel}
            />
          ))}
        </Slider>
      </Section>
    </div>
  );
};

export default PortfolioProjects;
